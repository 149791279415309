import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";


const firebaseConfig = {
  apiKey: "AIzaSyChZmZAvScD4f_vEcR-Umq9SXQ9IQxSgB4",
  authDomain: "certificate-app-46a4b.firebaseapp.com",
  projectId: "certificate-app-46a4b",
  storageBucket: "certificate-app-46a4b.appspot.com",
  messagingSenderId: "865525472010",
  appId: "1:865525472010:web:c4bb3a5d65686fa68138f3",
  measurementId: "G-356R3K3EXY"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
export default app;


