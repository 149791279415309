
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image
} from "@react-pdf/renderer";
import logo from "../../logo.png";


const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#fff",
    padding: 10
  },
  lastPage: {
    '@media print': {
      display: 'none',
    },
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
    flexDirection: "column",
    alignItems: "center"
  },
  indent: {
    lineHeight: 1.5
  },
  schoolname: {
    fontSize: 48,
    fontWeight: "700",
    color: "black",
    textAlign: "center",
    marginBottom: 10
  },
  schooladdress: {
    fontSize: 28,
    fontWeight: "500",
    color: "black",
    textAlign: "center",
    marginBottom: 10
  },
  logo: {
    width: "100px",
    height: "auto",
    marginBottom: 10
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
    color: "#FFD700", // Light golden color
    textAlign: "center",
    marginBottom: 10
  },
  footer: {
    position: "absolute",
    bottom: 10,
    left: 10,
    right: 10,
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: 15,
    fontWeight: 700,
  }
});

const CharacterCertificate = ({ studentName, year, gender, character }) => (
  <Document>
    <Page size="letter" orientation="landscape" style={[styles.page, styles.lastPage]} >
      <View style={styles.section}>
        <Text style={styles.schoolname}>ST. THOMAS' DAY SCHOOL</Text>
        <Text style={styles.schooladdress}>
          9,MIRZA GHALIB STREET,KOLKATA - 700087
        </Text>
        <Image style={styles.logo} src={logo} />
        <Text style={styles.title}>CERTIFICATE OF CHARACTER</Text>
        <Text style={styles.indent}>
          This is to certify that{" "}
          <Text style={{ textDecoration: "underline" }}>{studentName}</Text>{" "}
          has been a bonafide student of this school and has <br/> successfully
          completed I.S.C. Examination
          <Text style={{ textDecoration: "underline" }}> {year}</Text>{" "}.
        </Text>
        <Text style={styles.indent}>
          To the best of my knowledge{" "}
          <Text style={{ textDecoration: "underline" }}>{gender}</Text> bears{" "}
          <Text style={{ textDecoration: "underline" }}>{character}</Text>{" "}
          moral character.
        </Text>
        <View style={styles.footer}>
          <Text style={{ textDecoration: "underline" }}>
            Date : {new Date().toLocaleString()}
          </Text>
          <Text>PRINCIPAL</Text>
        </View>
      </View>
    </Page>
  </Document>
);

export default CharacterCertificate;
