import React, { useState, useRef } from 'react';
import { PDFViewer } from '@react-pdf/renderer';
import TransferCertificate from './TransferCertificate';
import {Container,Row,Col,Button} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom' 

function TranferSkeleton() {
  const [regNo, setRegNo] = useState('031/');
  const [studentName, setStudentName] = useState('');
  const [gender,setGender] = useState("");
  const[parent,setParent] = useState("(Father)")
  const [guardianName, setGuardianName] = useState('');
  const [startYear, setStartYear] = useState('');
  const [previousSchool, setPreviousSchool] = useState('');
  const [endYear, setEndYear] = useState('');
  const [character, setCharacter] = useState('Good');
  const [genderType,setGenderType] = useState("");
  const [currentClass, setCurrentClass] = useState('');
  const [fee, setFee] = useState('');
  const [dob, setDob] = useState('');
  const [dobWords, setDobWords] = useState('');
  const [promotionYear, setPromotionyear] = useState('');
  const componentRef = useRef();
 
  const navigate = useNavigate();
  return (
       <>
       <Container>
        <Row className='p-2 m-2 bg-light'>
          <Col md={1}>
          <Button variant='warning text-secondary' onClick={()=>navigate('/home')}>Home</Button>
          </Col>
          <Col md={11}>
            <p className='display-5 text-center  fw-bold'>Fill up the details:</p>
        <form>
        <input 
          type="text" 
          placeholder="Enter Registration Number" 
          value={regNo} 
          onChange={(e) => setRegNo(e.target.value)} 
        />
        <input 
          type="text" 
          placeholder="Enter student name" 
          value={studentName} 
          onChange={(e) => setStudentName(e.target.value)} 
        />
         <input 
          type="text" 
          placeholder="Enter Son/Daughter" 
          value={gender} 
          onChange={(e) => setGender(e.target.value)} 
        />
        <input 
          type="text" 
          placeholder="Enter Father/Mother" 
          value={parent} 
          onChange={(e) => setParent(e.target.value)} 
        />
        <input 
          type="text" 
          placeholder="Enter guardian name" 
          value={guardianName} 
          onChange={(e) => setGuardianName(e.target.value)} 
        />
        <label className='badge bg-dark'>Start Year</label>
        <input 
          type="date" 
          placeholder="Enter start year" 
          value={startYear} 
          onChange={(e) => setStartYear(e.target.value)} 
        />
        <input 
          type="text" 
          placeholder="Enter previous school if any" 
          value={previousSchool} 
          onChange={(e) => setPreviousSchool(e.target.value)} 
        />
        <label className='badge bg-dark'>End Year</label>
        <input 
          type="date" 
          placeholder="Enter end year" 
          value={endYear} 
          onChange={(e) => setEndYear(e.target.value)} 
        />
        <input 
          type="text" 
          placeholder="Enter Good/Bad" 
          value={character} 
          onChange={(e) => setCharacter(e.target.value)} 
        />
        <input 
          type="text" 
          placeholder="Enter His/Her" 
          value={genderType} 
          onChange={(e) => setGenderType(e.target.value)} 
        />
        <input 
          type="text" 
          placeholder="eg: XII(Twelve)" 
          value={currentClass} 
          onChange={(e) => setCurrentClass(e.target.value)} 
        />
        <input 
          type="text" 
          placeholder="Enter fee if needed" 
          value={fee} 
          onChange={(e) => setFee(e.target.value)} 
        />
        <label className='badge bg-dark'>DOB</label>
        <input 
          type="date" 
          placeholder="Enter date of birth" 
          value={dob} 
          onChange={(e) => setDob(e.target.value)} 
        />
        <input 
          type="text" 
          placeholder="Enter dob in words" 
          value={dobWords} 
          onChange={(e) => setDobWords(e.target.value)} 
        />
        <input 
          type="text" 
          placeholder="Remark for Examination YEAR" 
          value={promotionYear} 
          onChange={(e) => setPromotionyear(e.target.value)} 
        />
        </form>
       
          </Col>
        </Row>
       </Container>
                
      <div style={{ width: '100%', height: '100vh', overflow: 'auto' }}>
        <PDFViewer width="100%" height="100%">
          <TransferCertificate 
            ref={componentRef}
            regNo={regNo}
            studentName={studentName} 
            gender={gender}
            parent={parent}
            guardianName={guardianName}
            startYear={startYear}
            previousSchool={previousSchool}
            endYear={endYear} 
            character={character} 
            genderType={genderType}
            currentClass={currentClass}
            fee={fee}
            dob={dob}
            dobWords={dobWords}
            promotionYear={promotionYear}
          />
        </PDFViewer>
      </div>
   </>
  );
}

export default TranferSkeleton;
