import React, { useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../server/firebaseAuth";
import { useNavigate } from "react-router-dom";
import { Container, Button, Form, Row, Col, Image } from "react-bootstrap";
import logo from "../logo.png";

const Admin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    if(email ===""|| password===""){
      setError(true);
      return;
    }
    e.preventDefault();
    setError(false);
    setErrMsg('')
    try {
      await signInWithEmailAndPassword(auth, email, password)
        // Redirect or show admin page
        .then((userCredential) => {
          const user = userCredential.user;
          sessionStorage.setItem("user-token", JSON.stringify(user?.uid));
          navigate("/home");
          // console.log("logged In" + user?.uid);
        });
    } catch (error) {
      console.error(error.message);
      setError(true);
      setErrMsg("Email id or password is not correct. Please verify");
     
      // Handle login error
    }
  };

  return (
    <>
      <Container>
        <h1 className="display-5  bg-transparent text-white text-center p-4">
          <Image src={logo} alt="schoolLogo" width={100} height={100} />
          Certificate Application
        </h1>
        <Row >
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Form className="bg-light text-dark  m-5 fromDesign">
              <Form.Group className="mb-3" controlId="formGroupEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email"
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formGroupPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                />
              </Form.Group>
              {
                email&&password &&(
                  <Button
                  className="adminButton"
                  variant="outline-secondary"
                  onClick={handleLogin}
                >
                  Login
                </Button>
                )
              }              
              {
                error &&(
                  <p className="badge text-danger">{errMsg}</p>
                )
              }
            </Form>
          </Col>
        </Row>
      </Container>
      <p className="text-white text-center p-4">All rights reserved © ST. THOMAS' DAY SCHOOL</p>
    </>
  );
};

export default Admin;
