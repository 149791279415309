
import {
    Document,
    Page,
    Text,
    View,
    StyleSheet,
    
  } from "@react-pdf/renderer";
  
  
  const styles = StyleSheet.create({
    page: {
      flexDirection: "row",
      backgroundColor: "#fff",
      padding: 10
    },
   
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
      flexDirection: "column",
      alignItems: "center"
    },
    indent: {
      lineHeight: 1.5
    },
    cni: {
        fontSize: 25,
        fontWeight: "700",
        color: "black",
        textAlign: "center",
        marginBottom: 2
      },
    schoolname: {
      fontSize: 15,
      fontWeight: "700",
      color: "black",
      textAlign: "center",
      marginBottom: 1
    },
    affiliation: {
      fontSize: 12,
      fontWeight: "500",
      color: "black",
      textAlign: "center",
      marginBottom: 1
    },
    header: {
        position: "absolute",
        top: 90,
        left: 10,
        right: 10,
        flexDirection: "row",
        justifyContent: "space-between",
        fontSize: 15,
        fontWeight: 700,
      },
    appendix: {
      fontSize: 12,
      fontWeight: "bold",
      color: "black", 
      textAlign: "center",
      marginTop: 30 ,
    },
    transfer: {
        fontSize: 18,
        fontWeight: "bold",
        color: "black", 
        textAlign: "center",
        marginTop: 5 ,
      },
    footer: {
      position: "absolute",
      bottom: 250,
      left: 10,
      right: 10,
      flexDirection: "row",
      justifyContent: "space-between",
      fontSize: 15,
      fontWeight: 700,
    },
    additional: {
      position:"absolute",
      bottom: 80,
      left: 10,
      right: 10,
      flexDirection: "row",
      justifyContent:"flex-start",
      fontSize: 15,
      fontWeight: 700,
    },
    additional1: {
      position:"absolute",
      bottom: -20,
      left: 10,
      right: 10,
      flexDirection: "row",
      justifyContent:"flex-start",
      fontSize: 15,
      fontWeight: 700,
    },
    additional2: {
      position:"absolute",
      bottom: 20,
      left: 10,
      right: 10,
      flexDirection: "row",
      justifyContent:"flex-start",
      fontSize: 15,
      fontWeight: 700,
    },
    additional3: {
      position:"absolute",
      bottom: -40,
      left: 10,
      right: 10,
      flexDirection: "row",
      justifyContent:"flex-start",
      fontSize: 15,
      fontWeight: 700,
    }
  });
  
  const TransferCertificate = ({ regNo, studentName, gender, parent, guardianName, startYear, previousSchool, endYear, character, genderType, currentClass, fee, dob, dobWords, promotionYear }) => (
    <Document>
      <Page size="A4" orientation="portrait" style={[styles.page, styles.lastPage]} >
        <View style={styles.section}>
        <Text style={styles.cni}>ANGLO-INDIAN SCHOOLS IN INDIA</Text>
          <Text style={styles.schoolname}>ST. THOMAS' DAY SCHOOL (C N I)</Text>
          <Text style={styles.affiliation}>AFFILIATED TO COUNCIL FOR THE I.S.C. EXAMS. NEW DELHI </Text>       
          <Text style={styles.affiliation}>( ESTD. 1944)</Text>
          <View style={styles.header}>
            <Text style={{ textDecoration: "underline" }}>No</Text>
            <Text>Reg. No.: {regNo}</Text>
          </View>
          <Text style={styles.appendix}>Appendix VI (Article 71)</Text>
          <Text style={styles.transfer}>TRANSFER CHARACTER</Text>
          <Text style={styles.indent}>_______________</Text>
          <Text style={styles.indent}>
            This is to certify that{" "}
            <Text style={{ textDecoration: "underline" }}>{studentName}</Text>,{""}
            <Text style={{ textDecoration: "underline" }}>{gender}</Text>{" "}
             of {parent} Mr. <Text style={{ textDecoration: "underline" }}>{guardianName}</Text>{" "} 
            was admitted into this <b>ST.THOMAS' DAY SCHOOL,</b>9, Mirza Ghalib Street, Calcutta-700 087
            on the <Text style={{ textDecoration: "underline" }}> {startYear}</Text>{" "}. 
            on a Transfer Certificate from <Text style={{ textDecoration: "underline" }}> {previousSchool}</Text>{" "}School*
            and left on the <Text style={{ textDecoration: "underline" }}> {endYear}</Text>{" "} with a <Text style={{ textDecoration: "underline" }}> {character}</Text>{" "} Character.
          </Text>
          <Text style={styles.indent}>
          <Text style={{ textDecoration: "underline" }}>{genderType}</Text> when then reading in the ✝{" "}<Text style={{ textDecoration: "underline" }}>{currentClass}</Text>
            Standard and was paying fees at the rate of Rs.<Text style={{ textDecoration: "underline" }}>{fee}</Text> a month. All sums ‡ due to the School <Text style={{ textDecoration: "underline" }}>{fee}</Text> account have been paid, remitted or satisfactorily arranged for.
            <Text style={{ textDecoration: "underline" }}>{character}</Text>{" "}
            moral character.
          </Text>
          <Text style={styles.indent}>
          <Text style={{ textDecoration: "underline" }}>{genderType}</Text> date of birth, according to the Admission Register is {" "}<Text style={{ textDecoration: "underline" }}>{dob}</Text>
            (in words) <Text style={{ textDecoration: "underline" }}>{dobWords}</Text>
          </Text>
          ( The following addition information must be supplied if the scholar left at the end of the school year).
          <Text style={styles.indent}>
          Promotion has been $ {" "}<Text style={{ textDecoration: "underline" }}>{promotionYear}</Text>
          </Text>

          <View style={styles.footer}>
            <Text style={{ textDecoration: "underline" }}>
              Dated : {new Date().toLocaleString()}
            </Text>
            <Text>PRINCIPAL</Text>
          </View>
          <View style={styles.additional}>
            <Text>
            • The Grade of the School be stated as well as the name.
            </Text>
            <View style={styles.additional1}>
            <Text>
            + To be given in words.
            </Text>
            </View>
            <View style={styles.additional2}>
            <Text>
            ‡ Sums due to the School shall include all payments for which provision is made in rules supplied
              to the parent or guardian when the scholar was admitted in the school (Art.76 of the Code).
           </Text>
            </View>
            <View style={styles.additional3}>
            <Text>
            $ Granted or Refused.
            </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
  
  export default TransferCertificate;
  