import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Card, Button ,Navbar} from "react-bootstrap";
import icon from '../src/icon.png'
import icon1 from '../src/icon1.png'
import icon2 from '../src/icon2.png'

function Homepage(props) {
  const navigate = useNavigate();

  // Sign out function
  const signOutHandler = () => {
    // Clear user token from session storage
    sessionStorage.removeItem("user-token");
    props.setToken("");
    // Redirect to login page
    window.location.href = "/";
  };

  return (
    < div >
      <Container >
        <span className="display-6 text-white text-center ">Welcome Back!</span>
      <Button variant="primary"
           onClick={signOutHandler}
           className="m-2 p-2 text-primary bg-light bttn"
           >
          Sign Out
        </Button>      
          <Row>
          
          <Col className="p-4">
            <Card
              style={{ width: "20rem" }}
              className="card__option text-white p-4 text-center"
            >
              <Card.Img variant="top" src={icon} height={150}   />
              <Card.Body>
                <Card.Title className="text-secondary fw-bold">
                  Character Certificate
                </Card.Title>
                <Button
                  variant="warning"
                  onClick={() => navigate("/character-certificate")}
                >
                  Create Certificate
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col className="p-4 ">
            <Card
              style={{ width: "20rem" }}
              className="card__option text-white p-4 text-center"
            >
              <Card.Img variant="top" src={icon1} height={150}/>
              <Card.Body>
                <Card.Title className="text-secondary fw-bold">
                  Transfer Certificate
                </Card.Title>
                 <Button
                  variant="warning"
                  onClick={() => navigate("/transfer-certificate")}
                >
                  Create Certificate
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col className="p-4 ">
            <Card
              style={{ width: "20rem" }}
              className="card__option text-white p-4 text-center"
            >
              <Card.Img variant="top" src={icon2} height={150} />
              <Card.Body>
                <Card.Title className="text-secondary fw-bold">
                Certificate of Honour
                </Card.Title>
               
                <Button
                  variant="warning"
                  onClick={() => navigate("/honour-certificate")}
                >
                  Create Certificate
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>       
       
      </Container>
    </div>
  );
}

export default Homepage;
