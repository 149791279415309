import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Homepage from "./Homepage";
import CharacterSkeleton from "./certificates/character/CharacterSkeleton";
import TranferSkeleton from "./certificates/transfer/TransferSkeleton";
import HonourSkeleton from "./certificates/honour/HonourSkeleton"
import Admin from "./admin/Admin";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userToken, setToken]=useState(sessionStorage.getItem("user-token"));

  useEffect(() => {
    setIsLoggedIn( userToken !== "undefined" ? true: false );
  }, [isLoggedIn]);

  

  return (
    <>
    <Router>
      <Routes>
        {/* Public routes */}
        <Route path="/" element={<Admin />} />
           

        {/* Private routes */}
        {isLoggedIn ? (
          <>
            <Route path="/home" element={<Homepage setToken={setToken} />} />
            <Route path="/character-certificate" element={<CharacterSkeleton />} />
            <Route path="/transfer-certificate" element={<TranferSkeleton />} />
            <Route path="/honour-certificate" element={<HonourSkeleton />} />
          </>
        ) : (
          <Route path="/*" element={<Navigate to="/" />} />
        )}
      </Routes>
    </Router>
    </>
    
  );
}

export default App;
