import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image
} from "@react-pdf/renderer";
import logo from "../../logo.png";
import bg from '../../school.JPG';

const styles = StyleSheet.create({
  page: {
    position: "relative",
    
    fontFamily: "Helvetica"
  },
  backgroundImage: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: "100%",
    height: "100%",
    opacity: 0.5 
   
  },
  section: {
    marginTop: 1,
    padding: 1,
    flexGrow: 1,
    flexDirection: "column",
    alignItems: "center",
   
  },
  indent: {
    lineHeight: 1.5,
    backgroundColor: "rgba(234, 229, 241, 0.6)", 
    border: "2px solid #FFA500", 
    borderRadius:"9px",
    padding: 20,
    marginBottom: 10,
    textAlign: "center",
    fontSize: 18,
    color: "black" // Dark grey for better readability
  },
  schoolname: {
    fontSize: 48,
    fontWeight: "bold",
    color: "#003366", // Dark blue
    textAlign: "center",
    marginBottom: 10,
    marginTop: 10,
    letterSpacing: 2,
  },
  schooladdress: {
    fontSize: 22,
    fontWeight: "normal",
    color: "#003366", // Dark blue
    textAlign: "center",
    marginBottom: 10
  },
  logo: {
    width: 100,
    height: "auto",
    marginBottom: 10
  },
  title: {
    fontSize: 32,
    fontWeight: 700,
    color: "red", // Orange-red
    textAlign: "center",
    marginBottom: 20,
  },
  footer: {
    position: "absolute",
    bottom: 25,
    left: 10,
    right: 25,
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: 15,
    fontWeight: 600,
    color:"#00008B"
  },
  date: {
    textDecoration: "underline",
    fontWeight: "bold",
    color: "#00008B",
  }
});

const HonourCertificate = ({ studentName, standard, section, position, event, year }) => (
  <Document>
    <Page size="A4" orientation="landscape" style={styles.page}>
      
      <View style={styles.section}>
      <Image style={styles.backgroundImage} src={bg} />
        <Text style={styles.schoolname}>ST. THOMAS' DAY SCHOOL</Text>
        <Text style={styles.schooladdress}>
          9, MIRZA GHALIB STREET, KOLKATA - 700087
        </Text>
        <Image style={styles.logo} src={logo} />
        <Text style={styles.title}>CERTIFICATE OF HONOUR</Text>
        <Text style={styles.indent}>
          This is to certify that <Text style={{ textDecoration: "underline" }}>{studentName}</Text>{" "}
          of Class <Text style={{ textDecoration: "underline" }}> {standard}</Text>, {"\n"}
          Section <Text style={{ textDecoration: "underline" }}>{section}</Text>, has secured{" "}
          <Text style={{ textDecoration: "underline" }}>{position}</Text> position
        </Text>
        <Text style={styles.indent}>
          in <Text style={{ textDecoration: "underline" }}>{event}</Text>{" "} for the academic year
          <Text style={{ textDecoration: "underline" }}> {year}</Text>
        </Text>
        <View style={styles.footer}>
          <Text>
            <Text style={{ fontWeight: "bold" }}>Aniket Deb Roy</Text>{"\n"}
            Principal & Honorary Secretary
          </Text>
          <Text style={styles.date}>
            Date: {new Date().toLocaleDateString()}
          </Text>
        </View>
      </View>
    </Page>
  </Document>
);

export default HonourCertificate;
