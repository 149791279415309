import React, { useState, useRef } from 'react';
import { PDFViewer } from '@react-pdf/renderer';
import CharacterCertificate from './CharacterCertificate';
import {Container,Row,Col,Button} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom' 

function CharacterSkeleton() {
  const [studentName, setStudentName] = useState('');
  const [year, setYear] = useState('');
  const [gender, setGender] = useState('');
  const [character, setCharacter] = useState('Good');
  const componentRef = useRef();
 
  const navigate = useNavigate();
  return (
       <>
       <Container>
        <Row className='p-2 m-2 bg-light'>
          <Col md={1}>
          <Button variant='warning text-secondary' onClick={()=>navigate('/home')}>Home</Button>
          </Col>
          <Col md={11}>
            <p className='display-5 text-center  fw-bold'>Fill up the details:</p>
        <input 
          type="text" 
          placeholder="Enter student name" 
          value={studentName} 
          onChange={(e) => setStudentName(e.target.value)} 
        />
        <input 
          type="text" 
          placeholder="Enter year" 
          value={year} 
          onChange={(e) => setYear(e.target.value)} 
        />
        <input 
          type="text" 
          placeholder="Enter He/She" 
          value={gender} 
          onChange={(e) => setGender(e.target.value)} 
        />
        <input 
          type="text" 
          placeholder="Enter Good/Bad" 
          value={character} 
          onChange={(e) => setCharacter(e.target.value)} 
        />
          </Col>
        </Row>
       </Container>
                
      <div style={{ width: '100%', height: '100vh', overflow: 'auto' }}>
        <PDFViewer width="100%" height="100%">
          <CharacterCertificate 
            ref={componentRef}
            studentName={studentName} 
            year={year} 
            gender={gender} 
            character={character} 
          />
        </PDFViewer>
      </div>
   </>
  );
}

export default CharacterSkeleton;
