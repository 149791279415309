import React, { useState, useRef } from 'react';
import { PDFViewer } from '@react-pdf/renderer';
import HonourCertificate from './HonourCertificate';
import {Container,Row,Col,Button} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom' 

function CharacterSkeleton() {
  const [studentName, setStudentName] = useState('');
  const [standard, setStandard] = useState('');
  const [section, setSection] = useState('');
  const [position, setPosition] = useState('');
  const [event, setEvent] = useState('');
  const [year, setYear] = useState('');
  const componentRef = useRef();
 
  const navigate = useNavigate();
  return (
       <>
       <Container>
        <Row className='p-2 m-2 bg-light'>
          <Col md={1}>
          <Button variant='warning text-secondary' onClick={()=>navigate('/home')}>Home</Button>
          </Col>
          <Col md={11}>
            <p className='display-5 text-center  fw-bold'>Fill up the details:</p>
        <input 
          type="text" 
          placeholder="Enter student name" 
          value={studentName} 
          onChange={(e) => setStudentName(e.target.value)} 
        />
        <input 
          type="text" 
          placeholder="Enter class" 
          value={standard} 
          onChange={(e) => setStandard(e.target.value)} 
        />
        <input 
          type="text" 
          placeholder="Enter section" 
          value={section} 
          onChange={(e) => setSection(e.target.value)} 
        />
        <input 
          type="text" 
          placeholder="Enter position hold" 
          value={position} 
          onChange={(e) => setPosition(e.target.value)} 
        />
        <input 
          type="text" 
          placeholder="Enter event name" 
          value={event} 
          onChange={(e) => setEvent(e.target.value)} 
        />
       
        <input 
          type="text" 
          placeholder="Enter year" 
          value={year} 
          onChange={(e) => setYear(e.target.value)} 
        />
          </Col>
        </Row>
       </Container>
                
      <div style={{ width: '100%', height: '100vh', overflow: 'auto' }}>
        <PDFViewer width="100%" height="100%">
          <HonourCertificate 
            ref={componentRef}
            studentName={studentName} 
            standard={standard}
            section={section}
            position={position}
            event={event}
            year={year} 
           
          />
        </PDFViewer>
      </div>
   </>
  );
}

export default CharacterSkeleton;
